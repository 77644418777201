// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-unpublished-preview-js": () => import("./../../../src/pages/unpublishedPreview.js" /* webpackChunkName: "component---src-pages-unpublished-preview-js" */),
  "component---src-templates-all-collections-page-js": () => import("./../../../src/templates/allCollectionsPage.js" /* webpackChunkName: "component---src-templates-all-collections-page-js" */),
  "component---src-templates-checkout-success-page-js": () => import("./../../../src/templates/checkoutSuccessPage.js" /* webpackChunkName: "component---src-templates-checkout-success-page-js" */),
  "component---src-templates-collection-page-js": () => import("./../../../src/templates/collectionPage.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-templates-contact-success-page-js": () => import("./../../../src/templates/contactSuccessPage.js" /* webpackChunkName: "component---src-templates-contact-success-page-js" */),
  "component---src-templates-shop-category-page-js": () => import("./../../../src/templates/shopCategoryPage.js" /* webpackChunkName: "component---src-templates-shop-category-page-js" */),
  "component---src-templates-shop-item-page-js": () => import("./../../../src/templates/shopItemPage.js" /* webpackChunkName: "component---src-templates-shop-item-page-js" */)
}

